import React from "react";
import errorBoundary from '../src/assests/images/errorBoundaryNew.svg'
import secondaryPolygon from '../src/assests/images/secondaryPolygon.svg'
import dottedRectangle from '../src/assests/images/dottedRectangle.svg'
import dashedPolygon from '../src/assests/images/dashedPolygon.svg'
import { Button } from "reactstrap";
import { appUrl, websiteURL } from "./utils/helperFunctions/envLinks";
import Polygon from '../src/assests/images/Polygon8.png'
import BlueCurve from '../src/assests/images/blueCurve.png'
import { localStorageKeys } from "./utils/constants/localStorageKeys";
import * as Sentry from "@sentry/react";
import { clearLocalStorage } from "./utils/helperFunctions/localStorage";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }
  static getDerivedStateFromError(error) {
    var profile = JSON.parse(localStorage.getItem(localStorageKeys.PROFILE))
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "something_went_wrong",
      reason: error,
      userId: profile?.id
    });
    return { hasError: true, error: error };
  }
  componentDidCatch(error, info) {
    Sentry.captureException(error);
    if (error?.name == "ChunkLoadError") {
      clearLocalStorage();
      window.location.reload();
    }
  }

  visitContactUs() {
    let url = websiteURL() + '/contact'
    window.open(url, "_blank");
  };

  redirectToHome() {
    var token = localStorage.getItem(localStorageKeys.ACCESS_TOKEN)
    const url = appUrl()
    if (!token) {
      window.open(`${url}`, '_self');
    }
    else {
      window.open(`${url}`, '_self');
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div>
            <div className="error-page">
              {
                this.error?.name === 'ChunkLoadError' ?
                  <div id="error-tracking" className="error-page-text">

                    <h6 className="Oopstext"><span className="">Please</span> <span className='highlighted-text'>refresh the page.</span></h6>
                    <span className="oopsHelpingtext" >
                      This application has been updated, please refresh your browser to see the latest content.
                    </span>
                  </div>
                  :
                  <div id="error-tracking" className="error-page-text">

                    <h6 className="Oopstext"><span className="">Oops!</span> <span className='highlighted-text'>Something went wrong!</span></h6>
                    <span className="oopsHelpingtext" >
                      Please check your internet connection or try again later. We apologize for the inconvenience.
                    </span>
                  </div>
              }
              <span><img draggable={false} src={secondaryPolygon} className="secondaryPolygon-img" alt="" /></span>
              <span><img draggable={false} src={dashedPolygon} className="dashedPolygon-img" alt="" /></span>
              <span><img draggable={false} src={dottedRectangle} className="dottedRectangle-img" alt="" /></span>
              <span><img draggable={false} src={Polygon} className="polygon-img" alt="" /></span>
              <span><img draggable={false} src={BlueCurve} className="blue-curve-img" alt="" /></span>

              <div className="button-div">
                <Button className="backHomebtn" color="primary" onClick={() => { this.redirectToHome() }}>Back to home</Button>
                <Button className="contactUsbtn" color="secondary" onClick={() => { this.visitContactUs() }}>Contact us</Button>
              </div>
              <div className="img-div">
                <img draggable={false} src={errorBoundary} alt="" />
              </div>
              <br></br>
            </div>
          </div>

        </>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;  