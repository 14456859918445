import React, { Component } from 'react';
import { connect } from 'react-redux';
import { localStorageKeys } from './constants/localStorageKeys';
import { routesConstants } from './constants/routes';
import { caching } from './helperFunctions/caching';


export default function(ComposedComponent) {
    class Authentication extends Component {     
        componentDidMount() {
            caching()
            var authToken = localStorage.getItem(localStorageKeys.ACCESS_TOKEN)
            if(!authToken){
                if((window?.location?.href)?.includes("shared")){
                    const arr = window?.location.href.split("/shared")
                    localStorage.setItem(localStorageKeys.OPENPROJECTLINK,"/shared"+arr[1])
                }
                this.props.history.push(routesConstants.HOME);
            }
        }
        render() {
            return <ComposedComponent {...this.props} />
        }
    }
    
    function mapStateToProps(state) {
        return { 
            ...state
        };
    }

   return connect(mapStateToProps)(Authentication);

}