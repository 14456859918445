import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './App.css';
import { ToastProvider } from 'react-toast-notifications';
import { io } from 'socket.io-client'
import { BrowserTracing } from '@sentry/tracing';
import { socketURL } from './utils/helperFunctions/envLinks';
import * as Sentry from "@sentry/react";
import Auth from '../src/utils/authGuard'
import UserSession from './helperComponents/userSession';
import Chatbot from './views/chatbot/chatbot';
import Fallback from './helperComponents/fallback';
import { Provider } from 'react-redux';
import { persistor, store } from './redux-toolkit/store';
import '@xyflow/react/dist/style.css';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactFlowProvider } from '@xyflow/react';

Sentry.init({
  dsn: process.env.NODE_ENV == "production" && process.env.REACT_APP_SENTRY_KEY_NEW,
  environment: process.env.REACT_APP_STAGE,
  integrations: [new BrowserTracing()],
  release: process.env.REACT_APP_VERSION,
  tracesSampleRate: 1.0,
});

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
        return window.location.reload();
      }
      reject(error);
    });
  });
};

const Login = React.lazy(() => lazyRetry(() => import('./views/login/login')))
const Signup = React.lazy(() => lazyRetry(() => import('./views/signup/signup')))
const ForgotPassword = React.lazy(() => lazyRetry(() => import('./views/forgotPassword/forgotPassword')))
const ChangeEmail = React.lazy(() => lazyRetry(() => import('./views/changeEmail/changeEmail')))
const OTP = React.lazy(() => lazyRetry(() => import('./views/forgotPassword/enterCode')))
const UpdatePassword = React.lazy(() => lazyRetry(() => import('./views/forgotPassword/reEnterPassword')))
const Reactivate = React.lazy(() => lazyRetry(() => import('./views/profile/account/reactivate')))
const Deactivate = React.lazy(() => lazyRetry(() => import('./views/profile/account/successDeactivate')))
const MobileError = React.lazy(() => lazyRetry(() => import('./helperComponents/mobileError')))
const Payment = React.lazy(() => lazyRetry(() => import('./views/payment/checkout')))
const Builder = React.lazy(() => lazyRetry(() => import('./views/builder/index')))
const MokkupBuilder = React.lazy(() => lazyRetry(() => import('./views/mokkupBuilder/index')))
const Onboarding = React.lazy(() => lazyRetry(() => import('./views/onboarding/multistep')))
const Invite = React.lazy(() => lazyRetry(() => import('./views/onboarding/step3')))
const VerifyEmail = React.lazy(() => lazyRetry(() => import('./views/onboarding/verifyEmail')))
const Layout = React.lazy(() => lazyRetry(() => import('./views/layout/layout')))
const Templates = React.lazy(() => lazyRetry(() => import('./views/templates/templates')))
const TemplatesInfo = React.lazy(() => lazyRetry(() => import('./views/templates/templateInfo')))
const ErrorPage = React.lazy(() => lazyRetry(() => import('./helperComponents/404')))
const Status = React.lazy(() => lazyRetry(() => import('./views/payment/status')))
const Embed = React.lazy(() => lazyRetry(() => import('./views/mokkupBuilder/components/embed/Frame')))
const ImageSave = React.lazy(() => lazyRetry(() => import('./views/imageSave/saveImage')))
const Plans = React.lazy(() => lazyRetry(() => import('./views/onboarding/plans')))
const AcceptInvite = React.lazy(() => lazyRetry(() => import('./views/mokkupBuilder/components/accessModes/AcceptInvite')))
const SharedMode = React.lazy(() => lazyRetry(() => import('./views/mokkupBuilder/components/accessModes/SharedMode')))

function App() {

  const consoleWarn = console.warn;
  const SUPPRESSED_WARNINGS = ['chart', 'parser-blocking'];

  console.warn = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
      consoleWarn(msg, ...args);
    }
  };


  return (
    <div className="App">
      <Router>
        <ToastProvider>
          <Suspense fallback={<Fallback />}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route path="/changeEmail" component={Auth(ChangeEmail)} />
              <Route path="/enterOTP" component={OTP} />
              <Route path="/updatePassword" component={UpdatePassword} />
              <Route path="/onboarding" component={Auth(Onboarding)} />
              <Route path="/profile/reactivate" component={Reactivate} />
              <Route path="/deactivate" component={Auth(Deactivate)} />
              <Route path="/invite" component={Auth(Invite)} />
              <Route path="/verify" component={Auth(VerifyEmail)} />
              {/* <Route path="/builder" component={Auth(Builder)} /> Move this route in redux provider */}
              <Route path={['/share', '/screen', '/shared', '/embed', '/template/embed', '/builder']}>
                <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <ReactFlowProvider>
                      <Suspense fallback={<Fallback />}>
                        <Switch>
                          <Route path="/builder" component={Auth(MokkupBuilder)} />
                          <Route path="/screen/:_id" component={Auth(MokkupBuilder)} />
                          <Route exact path="/share/:_id" component={Auth(SharedMode)} />
                          <Route exact path="/shared/:_slug/join/:_token" component={Auth(AcceptInvite)} />
                          <Route exact path="/shared/:_slug/join" component={Auth(AcceptInvite)} />
                          <Route exact path="/shared/:_slug" component={Auth(AcceptInvite)} />
                          <Route exact path="/embed/:_id" component={Embed} />
                          <Route exact path="/template/embed/:_id" component={Embed} />
                          <Redirect to="/" />
                        </Switch>
                      </Suspense>
                    </ReactFlowProvider>
                  </PersistGate>
                </Provider>
              </Route>
              <Route path="/404" component={ErrorPage} />
              <Route path="/templates/:_id/:_name" component={Auth(Templates)} />
              <Route path="/templatesInfo/:_id" component={Auth(TemplatesInfo)} />
              <Route exact path="/mobile" component={MobileError} />
              <Route exact path="/checkout" component={Auth(Payment)} />
              <Route exact path="/payment" component={Auth(Status)} />
              <Route exact path="/imagesave" component={ImageSave} />
              <Route exact path="/plans" component={Auth(Plans)} />
              <Route path="/" component={Auth(Layout)} />
            </Switch>
          </Suspense>
        </ToastProvider>
      </Router>
      <UserSession />
    </div>
  );
}

export default App;
