import axios from 'axios';
import NProgress from 'nprogress';
import * as Sentry from "@sentry/react";
import store from '../redux/store';
import { checkUserSession, checkFeedback401 } from '../redux/actions/userAction';
import { apiEndpoints } from './constants/apiEndpoints';
import { localStorageKeys } from './constants/localStorageKeys';
import { baseURL } from './helperFunctions/envLinks';
import { getLocalStorageItem } from './helperFunctions/localStorage';
const service = axios.create({
  baseURL: baseURL()
});
let isRefreshing = false;
let failedQueue = [];
// Function to refresh the access token using the refresh token

async function refreshToken() {
  try {
    const response = await axios.post(`${baseURL()}${apiEndpoints.REFRESH}`, {
      refresh: getLocalRefreshToken(),
    });
    const { access_token } = response?.data?.content;
    localStorage.setItem(localStorageKeys.ACCESS_TOKEN, access_token);
    isRefreshing = false;
      // Retry the failed requests
      failedQueue.forEach((prom) => prom(access_token));
      failedQueue = [];
    return access_token;
  } catch (err) {
    isRefreshing = false;
    store.dispatch(checkUserSession(true));
    throw err;
  }
}

function getLocalAccessToken() {
  return getLocalStorageItem(localStorageKeys.ACCESS_TOKEN);
}

function getLocalRefreshToken() {
  return getLocalStorageItem(localStorageKeys.REFRESH_TOKEN);
}

service.interceptors.request.use(
  (config) => {
    let { url, progress } = config;
    if (progress !== false) {
      NProgress.start();
    }
    NProgress.configure({ showSpinner: false });
    const authToken = getLocalAccessToken();
    const workspaceId = getLocalStorageItem(localStorageKeys.WORKSPACEID);
    if (authToken) {
      config.headers['Authorization'] = 'Bearer ' + authToken;
      config.headers['workspace'] = workspaceId;
      config.headers['Tz-Value'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
service.interceptors.response.use(
  (response) => {
    NProgress.done();
    store.dispatch(checkFeedback401(false));
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    NProgress.done();
    if (error.response) {
      Sentry.captureException(error?.response?.data?.message);
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if (getLocalRefreshToken()) {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const token = await refreshToken();
              return service(originalRequest);
            } catch (_error) {
              store.dispatch(checkFeedback401(true));
              store.dispatch(checkUserSession(true));
              throw _error;
            }
          } else {
            return new Promise((resolve, reject) => {
              failedQueue.push((token) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(service(originalRequest));
              });
            });
          }
        } else {
          store.dispatch(checkFeedback401(true));
          store.dispatch(checkUserSession(true));
        }
      }
      // Handle other error statuses if needed
      if (error.response.status === 403 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
      if (error.response.status === 404 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
      if (error.response.status === 400 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
      if (error.response.status === 409 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
      if (error.response.status === 500 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
export default service;