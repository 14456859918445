import { createTransform } from "redux-persist";
import {
  BuilderStateD,
  initialBuilderState,
} from "../features/mokkupBuilder/builderSlice";
import { localStorageKeys } from "@/utils/constants/localStorageKeys";
import localforage from "localforage";

// redux-persist-code
export const builderTransform = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState, key) => {
    if (key === "builder") {
      if ((outboundState as BuilderStateD)?.pages?.length > 0)
        localforage.setItem(
          localStorageKeys.PERSISTED_BUILDER_STATE,
          outboundState
        );
    }

    return initialBuilderState;
  },
  { whitelist: ["builder"] }
);
